@media screen and  (max-width: 900px){
    .breakDisp{
     display: block!important;
    }
    .widthPref{
        width: 100%!important;
    }
    .cambioPref{
        padding-left:0px!important;
        padding-top: 2rem!important;
    }

}





@media screen and  (max-width: 600px){
.anchoPrefSeu{
    width: 100% !important;
}
}

.guardarBot{
    background-color: #E9E9EA!important;
}



.novePref{
    width: 100%!important;
     margin-top: 1rem!important; 
    display: inline-flex;
    align-items: center;
    justify-content: center;
     position: relative;
    box-sizing: border-box; 
     outline: 0px; 
      border: 0px;
      margin: 0px;
  font-weight: 600!important;
      font-size: 1.5rem;
      line-height: 1.75;
       min-width: 64px!important; 
      padding: 8px 22px;
      border-radius: 28px!important;
      color: rgba(0, 0, 0, 0.87)!important;
      background-color: #f9b934!important;
      box-shadow: none;
       /* min-width: calc(600px - 4rem)!important;  */
  }

.novePref:hover{
    background-color: #FF9800!important;
   
}

@media screen and  (max-width: 800px){
    .breakDisp2{
     display: block!important;
    }
    .widthPref2{
        width: 100%!important;
    }
    .cambioPref2{
        padding-left:0px!important;
        padding-top: 2rem!important;
    }

 
}

.espan{
    min-width: 100px !important;
    margin: auto !important;
}

.flexoNot{
    display: flex!important;
}

@media screen and  (max-width: 900px){
    .breakDisp3{
     display: block!important;
    }
    .widthPref3{
        width: 100%!important;
    }
    .cambioPref3{
        padding-left:0px!important;
        padding-top: 2rem!important;
    }

 
}


@media screen and  (max-width: 925px){
    .breakDisp4{
     display: block!important;
    }
    .widthPref4{
        width: 100%!important;
    }
    .cambioPref4{
        padding-left:0px!important;
        padding-top: 2rem!important;
    }

    
}

@media screen and  (max-width: 925px){
.generalPref{
    
    min-width:auto!important;
    
}

}
@media screen and  (max-width: 775px){
.generalPref2{
    
   max-width:100%!important;
    
}
}

.emailPrefGen{
    background-color:#fff!important;
    font-weight: 600!important;
}

.margeneoAvatar{
    margin: auto;
}

@media screen and  (max-width: 730px){
    .breakDisp5{
     display: block!important;
    }
    .widthPref5{
        width: 100%!important;
    }
    .cambioPref5{
        padding-left:0px!important;
        padding-top: 2rem!important;
    }

 
}

@media screen and  (max-width: 775px){
    .breakDisp6{
     display: block!important;
    }
    .widthPref6{
        width: 100%!important;
    }
    .cambioPref6{
        padding-left:0px!important;
        padding-top: 2rem!important;
    }

 
}

.dialogoElim{
    max-width: 500px!important;
    margin: auto !important;
}

.widtheo2{
    width:calc(100vw - 320px - 8vw - 2rem);
     max-width: 650px;
} 


@media screen and (max-width: 800px){
    .breakDisp7{
     display: block!important;
    }
    .widthPref7{
        width: 100%!important;
    }
    .cambioPref7{
        padding-left:0px!important;
        padding-top: 2rem!important;
    }
    .widtheo2{
        width:calc(100vw - 8vw);
        max-width: -webkit-fill-available!important;
        padding-left: 0px!important;
        padding-right: 0px!important;
        padding-top:0px!important;
    }

 
}
@media print {
    .notaCom{
        min-width: 55rem!important;
    }
}

.desabWeb{
    background-color: transparent!important;
}

.desabWeb .MuiTypography-root{
    background-color: transparent!important;
}

.seleccionFle{
    border-color: transparent transparent #fff!important;
    border-style: solid;
    border-width: 3px 11px 11px 11px;
    top: 11px; 
    content: "";
    height: 0;
   /*  left: 11vw; */
    position: absolute; 
    width: 0; 
    color: #fff;
    z-index: 1;
}

.triangle{
    fill: transparent;
    stroke: #404042;
    stroke-width: 4;
    
}

.seleccionFle2{
    border-color: transparent transparent #fff!important;
    border-style: solid;
    border-width: 3px 11px 11px 11px;
    top: 10px; 
    content: "";
    height: 0;
    right: 50px;
    position: absolute; 
    width: 0; 
    color: #fff;
}

.botonColorPref{
    background: #f9b934!important;
    border-radius: 99999px!important;
    border:0px!important;
    box-shadow: none!important;
    font-weight: 600!important;
}

.botonColorPref:hover{
    background: #FF9800!important;
}

.formaWeb{
    padding-top: 16px!important;
    padding-bottom: 8px!important;
}

.otroInputWeb{
    text-align: center!important;
    justify-content: center!important;
    display: flex!important;
    margin: auto!important;
}

.stamp {
    right: 20% !important;
    bottom: 35% !important;
   /*  bottom: 20% !important; */
    position:absolute!important;
    transform: rotate(-40deg);
      color: #2e7d32;
      font-size: 1.5rem;
      font-weight: 700;
      border: 0.25rem solid #2e7d32;
      display: inline-block;
      padding: 0.25rem 1rem;
      text-transform: uppercase;
      border-radius: 1rem;
      font-family: 'Courier';
      -webkit-mask-image: url('https://res.cloudinary.com/dqj6poiis/image/upload/v1673316851/stamps/grunge_kv73cp_fjguwv.png');
    -webkit-mask-size: 944px 604px;
    mix-blend-mode: multiply;
  }

  .botonDown{
    padding-top: 4px !important;
    padding-bottom:4px !important;
    padding-left: 4px!important;
    padding-right: 8px!important;
    border-radius: 8px!important;
    /* background-color: #fff!important; */
    color: #1e2022 !important;
  }


  .stamp2 {
    right: 10% !important;
    bottom: 20% !important;
   /*  bottom: 20% !important; */
    position:absolute!important;
    transform: rotate(-10deg);
      color: #e74c3c;
      font-size: 1.5rem;
      font-weight: 700;
      border: 0.25rem solid #e74c3c;
      display: inline-block;
      padding: 0.25rem 1rem;
      text-transform: uppercase;
      border-radius: 1rem;
      font-family: 'Courier';
      -webkit-mask-image: url('https://res.cloudinary.com/dqj6poiis/image/upload/v1673316851/stamps/grunge_kv73cp_fjguwv.png');
    -webkit-mask-size: 944px 604px;
    mix-blend-mode: multiply;
  }

  .inizio .MuiFormControlLabel-label{
    width: inherit !important;
  }

  .contenedor1 {
    position: relative;
    width: 100%;
    
  }

  
 .overlay{
    position: absolute;
  top: 7.4%;
  bottom: 0;
  left: 7.4%;
  right: 0;
  height: 85%;
  width: 85%;
  opacity: 0;
  transition: .3s ease;
  background-color: #fff;
 border-radius: 100%;
  border:none;
    /* display: none!important; */
 }

 .iconEdit {
    /* color: #004e56; */
    color: black;
    font-size: 40px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
  }

  .iconEdit:hover{
    opacity: 1;
  }



 .contenedor1:hover .overlay {
    opacity: 0.75;
  }

  .overlay2{
    position: absolute;
  top: 7.4%;
  bottom: 0;
  left: 7.4%;
  right: 0;
  height: 85%;
  width: 85%;
  opacity: 1;
  
  background-color: #fff;
 border-radius: 100%;
  border:none;
 
 }

 .imagoMundi{
    /*  background-color: brown!important; */
     border-width: 4px!important;
     width: 6.4rem!important;
     height: 6.4rem!important;
     
 }
 
 .imagoMundi:hover{
     /*  background-color: brown!important; */
      border-width: 4px!important;
      width: 6.4rem!important;
      height: 6.4rem!important;
  }

  .noHover:hover{
    background-color: transparent!important;

}